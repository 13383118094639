import React from 'react';
import Form from '../components/Form';
import Layout from '../components/Layout';
import Module from '../components/Module';
import SimpleHeaderModule from '../components/SimpleHeaderModule';

export default () => (
  <Layout
    pageTitle="Have Feedback? Need Support? Contact Miter!"
    description="Get in touch with Miter's team. We love to hear from you!"
  >
    <SimpleHeaderModule title="Contact &amp; Support">
      We'd love to hear from you. For real. Feedback, questions, bug reports, whatever. Put your stuff in and hit the
      button.
    </SimpleHeaderModule>

    <Module container="BoxLight" width="Narrow">
      <Form description="Contact form" hubspotFormId={process.env.GATSBY_CONTACT_FORM_ID} submitLabel="Send">
        <label className="MultiField">
          Name
          <div className="Row">
            <input
              autoFocus
              type="text"
              name="firstname"
              data-name="firstname"
              className="TextField BodyMedium"
              maxLength={256}
              placeholder="First"
              required
            />
            <input
              type="text"
              name="lastname"
              data-name="lastname"
              className="TextField BodyMedium"
              maxLength={256}
              placeholder="Last"
              required
            />
          </div>
        </label>
        <label>
          Email
          <input
            type="email"
            name="email"
            data-name="email"
            className="TextField BodyMedium"
            maxLength={256}
            required
          />
        </label>
        <label>
          Message
          <textarea name="message" data-name="message" className="TextField BodyMedium" maxLength={256} required />
        </label>
      </Form>
    </Module>
    <Module width="Narrow">
      <p>
        Note that Miter is software from a small, early-stage startup. We're not yet at the stage where we have a
        dedicated support team with set hours, SLAs, forums, or a knowledge base—but we will prioritize getting back to
        you as fast as we can!
      </p>
      <p>
        If you'd prefer, you can email <a href="mailto:support@miter.co">support@miter.co</a> rather than filling out
        the form above.
      </p>
    </Module>
  </Layout>
);
